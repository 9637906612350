import React, { useEffect, useRef, useState, useCallback } from 'react';
import {
  Flex,
  Img,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
  Button,
  Textarea,
  Box,
  List,
  ListItem,
  InputRightAddon,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Input,
} from '@chakra-ui/react';
import {
  MdChatBubbleOutline,
  MdClose,
  MdExpandMore,
  MdExpandLess,
} from 'react-icons/md';
import chatLogo from './assets/images/chatLogo.svg';
import filledLogo from './assets/images/filledLogo.svg';
import online from './assets/images/online.svg';
import { IoMdRefresh, IoMdSend } from 'react-icons/io';
import {
  FundingSourceService,
  SNSChatBotService,
  PaymentTypeService,
  CoPayFsService,
  FetchFareEstimate,
  RiderBalanceService,
} from './services/Service';
import { ThreeDots } from 'react-loader-spinner';
import { debounce } from 'lodash';
import { useLocation } from 'react-router-dom';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ChatWidget = () => {
  const query = useQuery();
  let phone_no = query.get('PhoneNo');
  // phone_no = '2222221111'
  let affiliation_id = query.get('iAffiliateID');
  affiliation_id = '20'
  let family_id = query.get('iFamilyID');
  family_id = '4'
  let login_user_id = query.get('iUserID');

  const [isChatOpen, setIsChatOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [baseMessage, setBaseMessage] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [focusedSuggestionIndex, setFocusedSuggestionIndex] = useState(-1);
  const [hoveredSuggestionIndex, setHoveredSuggestionIndex] = useState(-1);
  const [openDropdown, setOpenDropdown] = useState(null);
  const [pickupLoading, setPickupLoading] = useState(false);
  const [dropoffLoading, setDropoffLoading] = useState(false);
  const [pickupSuggestions, setPickupSuggestions] = useState(null);
  const [dropoffSuggestions, setDropoffSuggestions] = useState(null);
  const [stopSuggestions, setStopSuggestions] = useState(null);
  const [fundingSourceLoading, setFundingSourceLoading] = useState(false);
  const [fundingSourceSuggestions, setFundingSourceSuggestions] = useState([]);
  const [selectedFundingSource, setSelectedFundingSource] = useState('1');
  const [paymentTypeSuggestions, setPaymentTypeSuggestions] = useState([]);
  const [paymentTypeLoading, setPaymentTypeLoading] = useState(false);
  const [stopLoading, setStopLoading] = useState(false);
  const faqRef = useRef();
  const textareaRef = useRef();
  const suggestionRefs = useRef([]);
  const dropdownRef = useRef(null);
  const [submitted, setSubmitted] = useState(false);
  let chatSocket = useRef(null);
  const [suggestionText, setSuggestionText] = useState('');
  const [uuid, setUuid] = useState();
  const [argsState, setArgsState] = useState({});

  const [changesUpdated, setChangesUpdated] = useState(false);

  // State to hold all API response times in milliseconds
  const [apiResponseTimes, setApiResponseTimes] = useState([]);

  console.log('argsState', argsState);
  const [selectedAffiliate, setSelectedAffiliate] = useState(
    ''
  );

  const [affiliatesDisabled, setAffiliatesDisabled] = useState(false);

  // Add states and functions outside the renderText function
  const [copayFSSuggestions, setCopayFSSuggestions] = useState([]);
  const [copayFSLoading, setCopayFSLoading] = useState(false);

  // Add states and functions outside the renderText function
  const [copayPaymentSuggestions, setCopayPaymentSuggestions] = useState([]);
  const [copayPaymentLoading, setCopayPaymentLoading] = useState(false);
  const [disableCopayDropdown, setDisableCopayDropdown] = useState(false);
  const [selectedCopayFsId, setSelectedCopayFsId] = useState('');
  const [affiliates, setAffiliates] = useState([]);
  const [riderBalance, setRiderBalance] = useState('');


  const formatPhoneNumber = (input) => {
    // Convert input to string if it's not already
    let phone = String(input);

    // Check if the phone number is already formatted (e.g., '111-111-1111')
    const formattedPattern = /^\d{3}-\d{3}-\d{4}$/;
    if (formattedPattern.test(phone)) {
      return phone; // Return the input if it's already formatted correctly
    }

    // Remove any non-numeric characters
    phone = phone.replace(/\D/g, '');

    // Ensure the phone number is 10 digits long
    if (phone.length !== 10) {
      return input; // Return the original input if it's not 10 digits
    }

    // Format the number as '111-111-1111'
    return `${phone.slice(0, 3)}-${phone.slice(3, 6)}-${phone.slice(6)}`;
  };

  const [phoneNumber, setPhoneNumber] = useState('');

  useEffect(() => {
    // Get the phone number from the query

    // Format the phone number
    const formattedPhoneNo = formatPhoneNumber(phone_no);

    // Update the state with the formatted phone number
    setPhoneNumber(formattedPhoneNo);
  }, [phone_no]); // Re-run the effect when query changes


  useEffect(() => {
    // Fetch or update the affiliates data based on the affiliation_id
    if (Array.isArray(affiliation_id)) {
      // Fetch your affiliates data here and set it using setAffiliates
      // const fetchedAffiliates = [
      //   { id: 1, name: 'Digital Marketing Solutions' },
      //   { id: 2, name: 'Global Tech Innovations' },
      //   { id: 3, name: 'FastTrack Financial Services' },
      //   { id: 4, name: 'Prime Logistics Partners' },
      // ]; // Replace this with your actual data fetching logic

      setAffiliates(affiliation_id);
      setAffiliatesDisabled(false); // Enable dropdown if affiliates are fetched
    }
    else {
      // setSelectedAffiliate(affiliation_id)
    }
  }, [affiliation_id]);

  const handleAffiliateChange = (event) => {
    const selectedId = event.target.value; // Get the selected affiliate ID
    setSelectedAffiliate(selectedId);
  };

  const updateInitialMessage = affiliate => {
    const initialMessage = {
      answer:
        phoneNumber === null
          ? `Hi I am Sam, you have selected ${affiliate}. Please provide me the Phone# to start the conversation.`
          : `Hi I am Sam, you have selected ${affiliate}. How may I assist you with ride booking today?`,
      query: '',
      loading: false,
      isUser: false,
      errorMessage: ""
    };

    setMessages([initialMessage]);
  };

  useEffect(() => {
    const newUuid = generateRandomNumber().toString();
    setUuid(newUuid);
    updateInitialMessage(selectedAffiliate);
  }, [selectedAffiliate]);

  const initialMessage = (text) => {
    return {
      answer:
        phone_no === null
          ? 'Hi I am Sam, please provide me the Phone# to start the conversation'
          : text,
      query: '',
      loading: false,
      isUser: false,
      errorMessage: ''
    };
  };


  const fetchCustomerName = async (newUuid) => {
    const formatPhone = formatPhoneNumber(phone_no)
    const payload = {
      ss_id: newUuid,
      phone_number: formatPhone || '',
      affiliate_id: selectedAffiliate || affiliation_id || '',
      login_id: login_user_id || '',
      family_id: family_id || '',
      msg: 'hey',
      ...(Object.keys(argsState).length > 0 && { args: argsState }),
    };

    if (phone_no !== null) {
      const response = await SNSChatBotService(payload);

      if (response?.success) {
        // 'Hi I am Sam, how may I assist you with ride booking today?'
        setMessages([initialMessage(response?.message?.text)]);
      }
    } else {
      setMessages([initialMessage()]);
    }
  }
  useEffect(() => {
    const newUuid = generateRandomNumber().toString();
    fetchCustomerName(newUuid)
    setUuid(newUuid);
  }, [phone_no]);

  function generateRandomNumber() {
    return Math.floor(Math.random() * 1000) + 1;
  }

  useEffect(() => {
    // const chatWebSocketUrl = `ws://54.211.31.29:5000/invoke_ner?affiliate_id=${selectedAffiliate || affiliation_id || '20'
    //   }&phone_number=${phoneNumber || ''}&family_id=${family_id || ''}`;

    const chatWebSocketUrl = `wss://api-itcurves.aidevlab.com/invoke_ner?affiliate_id=${selectedAffiliate || affiliation_id || '20'}&phone_number=${phoneNumber || ''}&family_id=${family_id || ''}`;

    chatSocket.current = new WebSocket(chatWebSocketUrl);

    chatSocket.current.onopen = () => {
      console.log('Chat WebSocket connection opened');
    };

    chatSocket.current.onmessage = event => {
      if (submitted) {
        setSubmitted(false);
        return;
      }
      const eventParsed = JSON.parse(event.data);
      addLlmMessage(eventParsed, eventParsed.recommendations);
    };

    chatSocket.current.onerror = error => {
      console.error('WebSocket error:', error);
    };

    chatSocket.current.onclose = event => {
      console.log('WebSocket connection closed:', event);
    };

    return () => {
      if (chatSocket.current) {
        chatSocket.current.close();
      }
    };
  }, [submitted]);

  useEffect(() => {
    const handleClickOutside = event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpenDropdown(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const ScrollToBottom = () => {
    if (faqRef.current) {
      faqRef.current.scrollTop = faqRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    ScrollToBottom();
  }, [messages, isChatOpen]);

  const toggleChat = () => {
    setIsChatOpen(!isChatOpen);
  };

  const addUserMessage = message => {
    const userMsgDiv = {
      query: message,
      answer: '',
      loading: true,
      isUser: true,
    };
    setMessages(prevMessages => [...prevMessages, userMsgDiv]);
  };

  const addLlmMessage = (response, recommendations) => {
    if (response?.success) {
      setSuggestions(response?.response);
      setSuggestionText(response?.address);
    } else {
      setSuggestionText('');
      setSuggestions([]);
    }
  };

  const handleSubmit = async message => {
    setApiResponseTimes([]);
    if (message.trim() === '') {
      if (changesUpdated) {
        message = 'confirm'
      } else {
        return;
      }
    }

    setSubmitted(true);
    setSuggestions([]);
    addUserMessage(message);

    setNewMessage('');
    setSuggestions([]);
    setFocusedSuggestionIndex(-1);
    textareaRef.current.style.height = '80px';
    const payload = {
      ss_id: uuid,
      phone_number: phoneNumber || '',
      affiliate_id: selectedAffiliate || affiliation_id || '',
      login_id: login_user_id || '',
      family_id: family_id || '',
      msg: message,
      ...(Object.keys(argsState).length > 0 && { args: argsState }),
    };

    try {
      setChangesUpdated(false)
      setLoading(true);

      const response = await SNSChatBotService(payload);

      if (response?.success) {
        let riderBalanceResponse;
        if (response?.message?.args?.client_id) {
          let riderPayload = {
            riderid: response?.message?.args?.client_id,
            programid: response?.message?.args?.program_id || '12'
          }
          // Capture the start time
          const startTime = new Date().getTime();
          riderBalanceResponse = await RiderBalanceService(riderPayload);
          const endTimeSNS = new Date().getTime();
          const totalTimeSNS = endTimeSNS - startTime;
          setApiResponseTimes(prevTimes => [...prevTimes, totalTimeSNS]);
          setRiderBalance(riderBalanceResponse?.RiderProgramBalance)
          // console.log("riderBalanceResponse::>", riderBalanceResponse);
        }
        const answer = response?.message;
        const extractedString = answer?.text?.split('Your')[0].trim();
        setAffiliatesDisabled(
          extractedString === 'Ride booked successfully!' ? false : true
        );


        if (answer.args) {
          setArgsState(answer.args);

          setMessages(prevMessages => {
            const updatedMessages = [...prevMessages];
            const currentMessageIndex = updatedMessages.length - 1;
            updatedMessages[currentMessageIndex] = {
              ...updatedMessages[currentMessageIndex],
              answer: answer?.text || answer?.error,
              loading: false,
              args: answer.args,
              RiderProgramBalance: riderBalanceResponse?.RiderProgramBalance,
              errorMessage: answer?.error
            };
            return updatedMessages;
          });
        } else {
          setMessages(prevMessages => {
            const updatedMessages = [...prevMessages];
            const currentMessageIndex = updatedMessages.length - 1;
            updatedMessages[currentMessageIndex] = {
              ...updatedMessages[currentMessageIndex],
              answer: answer.text || answer?.error,
              loading: false,
              errorMessage: answer?.error
            };
            return updatedMessages;
          });
        }
      } else {
        throw new Error('Invalid response structure');
      }
    } catch (error) {
      console.error('Error:', error);
      setLoading(false);
      setMessages(prevMessages => {
        const updatedMessages = [...prevMessages];
        const currentMessageIndex = updatedMessages.length - 1;
        updatedMessages[currentMessageIndex] = {
          ...updatedMessages[currentMessageIndex],
          answer:
            'There was an issue processing your request. Please try again.',
          loading: false,
        };
        return updatedMessages;
      });
    } finally {
      setLoading(false);
    }
  };

  const renderMessageText = text => {
    const lines = text?.split('\n').filter(line => line.length >= 5);

    return lines?.map((line, index) => {
      // Replace text between '**' with bold (strong) tags
      const formattedLine = line.replace(/\*\*(.*?)\*\*/g, (match, p1) => `<strong>${p1}</strong>`);

      // Render the formatted text as HTML
      return <p key={index} dangerouslySetInnerHTML={{ __html: formattedLine }} />;
    });
  };


  const fetchAddressSuggestions = async (address, type) => {
    try {
      console.log('address::>', address);
      // let result;
      // if (type === 'stop') {
      //   result = address?.trim();
      // } else {
      // const regex = /\d.*$/;
      // result = address.match(regex);
      // }
      // console.log('result::>', result);

      const response = await fetch(
        `https://api-itcurves.aidevlab.com/add_suggestions?address=${address}`
      );
      const data = await response.json();
      if (type === 'pickup') {
        setPickupSuggestions(data.response);
      } else if (type === 'dropoff') {
        setDropoffSuggestions(data.response);
      } else {
        setStopSuggestions(data.response);
      }
    } catch (error) {
      console.error('Error fetching address suggestions:', error);
    }
  };

  const handleAddressSelect = async (address, type, legIndex, stopIndex) => {
    // Handle loading state for dropoff and pickup
    if (type === 'dropoff') {
      setDropoffLoading(true);
      setDropoffLoading(false);
    } else if (type === 'pickup') {
      setPickupLoading(true);
      setPickupLoading(false);
    }

    setOpenDropdown(null);

    setMessages(prevMessages => {
      const updatedMessages = [...prevMessages];
      const currentMessageIndex = updatedMessages.length - 1;
      const pickupLegs =
        updatedMessages[currentMessageIndex]?.args?.pickup_legs || [];

      let updatedArgs;
      if (type === 'pickup') {
        setChangesUpdated(true)
        updatedArgs = {
          ...updatedMessages[currentMessageIndex].args,
          pickup_legs: pickupLegs.map((leg, i) =>
            i === legIndex ? { ...leg, pickup_address: address } : leg
          ),
        };
      } else if (type === 'dropoff') {
        setChangesUpdated(true)
        updatedArgs = {
          ...updatedMessages[currentMessageIndex].args,
          pickup_legs: pickupLegs.map((leg, i) =>
            i === legIndex ? { ...leg, dropoff_address: address } : leg
          ),
        };
      } else if (type === 'stop') {
        setChangesUpdated(true)
        // New handling for stop addresses
        updatedArgs = {
          ...updatedMessages[currentMessageIndex].args,
          pickup_legs: pickupLegs.map((leg, i) =>
            i === legIndex
              ? {
                ...leg,
                stops: leg.stops.map((stop, j) =>
                  j === stopIndex
                    ? { ...stop, stop_address: address } // Update stop address
                    : stop
                ),
              }
              : leg
          ),
        };
      }

      const updatedAnswer = updatedMessages[currentMessageIndex].answer.replace(
        type === 'pickup'
          ? /(Pickup Address:\s*)(.*?)(\n|$)/
          : type === 'dropoff'
            ? /(Dropoff Address:\s*)(.*?)(\n|$)/
            : /(Stop Address:\s*)(.*?)(\n|$)/, // Added case for stop address
        `$1${address}$3`
      );

      updatedMessages[currentMessageIndex] = {
        ...updatedMessages[currentMessageIndex],
        answer: updatedAnswer,
        args: updatedArgs,
      };

      return updatedMessages;
    });

    setArgsState(prevArgs => {
      let updatedArgs;
      if (type === 'pickup') {
        updatedArgs = {
          ...prevArgs,
          pickup_legs: prevArgs.pickup_legs.map((leg, i) =>
            i === legIndex ? { ...leg, pickup_address: address } : leg
          ),
        };
      } else if (type === 'dropoff') {
        updatedArgs = {
          ...prevArgs,
          pickup_legs: prevArgs.pickup_legs.map((leg, i) =>
            i === legIndex ? { ...leg, dropoff_address: address } : leg
          ),
        };
      } else if (type === 'stop') {
        // Handling for stop address
        updatedArgs = {
          ...prevArgs,
          pickup_legs: prevArgs.pickup_legs.map((leg, i) =>
            i === legIndex
              ? {
                ...leg,
                stops: leg.stops.map((stop, j) =>
                  j === stopIndex
                    ? { ...stop, stop_address: address } // Update stop address
                    : stop
                ),
              }
              : leg
          ),
        };
      }
      return updatedArgs;
    });
  };

  const fetchFundingSourceSuggestions = async () => {
    try {
      setFundingSourceLoading(true);
      const startTimeSNS = new Date().getTime();
      const response = await FundingSourceService({
        affiliate_id: selectedAffiliate || affiliation_id || '20',
      });
      const endTimeSNS = new Date().getTime();
      const totalTimeSNS = endTimeSNS - startTimeSNS;
      setApiResponseTimes(prevTimes => [...prevTimes, totalTimeSNS]);
      if (response.FundingSources) {
        setChangesUpdated(false)
        setFundingSourceSuggestions(response.FundingSources);
      } else {
        setFundingSourceSuggestions([]);
      }
    } catch (error) {
      console.error('Error fetching funding source suggestions:', error);
    } finally {
      setFundingSourceLoading(false);
    }
  };

  const handleFundingSourceSelect = async source => {
    try {
      setPaymentTypeLoading(true);
      setChangesUpdated(false)
      // Capture the start time
      const startTime = new Date().getTime();

      const responsePayment = await PaymentTypeService(source.FS_ID.toString());
      const endTimeSNS = new Date().getTime();
      const totalTimeSNS = endTimeSNS - startTime;
      setApiResponseTimes(prevTimes => [...prevTimes, totalTimeSNS]);
      if (responsePayment.PaymentTypes) {
        handlePaymentTypeSelect(responsePayment.PaymentTypes[0]);
      }
    } catch (error) {
      console.error('Error fetching payment type suggestions:', error);
    } finally {
      setPaymentTypeLoading(false);
    }

    setSelectedFundingSource(source.FS_ID);
    setOpenDropdown(null);

    setMessages(prevMessages => {
      const updatedMessages = [...prevMessages];
      const currentMessageIndex = updatedMessages.length - 1;
      updatedMessages[currentMessageIndex] = {
        ...updatedMessages[currentMessageIndex],
        answer: updatedMessages[currentMessageIndex].answer.replace(
          /(Funding Source:\s*)(.*?)(\n|$)/,
          `$1${source.FS_NAME}$3`
        ),
        args: {
          ...updatedMessages[currentMessageIndex].args,
          funding_source: source.FS_NAME,
        },
      };
      setChangesUpdated(true)
      return updatedMessages;
    });

    setArgsState(prevArgs => ({
      ...prevArgs,
      funding_source: source.FS_NAME,
    }));
  };

  const fetchPaymentTypeSuggestions = async () => {
    try {
      setChangesUpdated(false)
      setPaymentTypeLoading(true);
      // Capture the start time
      const startTime = new Date().getTime();
      const response = await PaymentTypeService(
        selectedFundingSource.toString()
      );
      const endTimeSNS = new Date().getTime();
      const totalTimeSNS = endTimeSNS - startTime;
      setApiResponseTimes(prevTimes => [...prevTimes, totalTimeSNS]);
      if (response.PaymentTypes) {
        setPaymentTypeSuggestions(response.PaymentTypes);
      } else {
        setPaymentTypeSuggestions([]);
      }
    } catch (error) {
      console.error('Error fetching payment type suggestions:', error);
    } finally {
      setPaymentTypeLoading(false);
    }
  };

  const handlePaymentTypeSelect = type => {
    setOpenDropdown(null);

    setMessages(prevMessages => {
      const updatedMessages = [...prevMessages];
      const currentMessageIndex = updatedMessages.length - 1;
      updatedMessages[currentMessageIndex] = {
        ...updatedMessages[currentMessageIndex],
        answer: updatedMessages[currentMessageIndex].answer.replace(
          /(Payment Type:\s*)(.*?)(\n|$)/,
          `$1${type}$3`
        ),
        args: {
          ...updatedMessages[currentMessageIndex].args,
          payment_type: type,
        },
      };
      setChangesUpdated(true)
      return updatedMessages;
    });

    setArgsState(prevArgs => ({
      ...prevArgs,
      payment_type: type,
    }));
  };

  // Function to fetch Copay FS suggestions
  const fetchCopayFSSuggestions = async () => {
    try {
      setChangesUpdated(false)
      setCopayFSLoading(true);
      // await new Promise(resolve => setTimeout(resolve, 1000));
      // Capture the start time
      const startTime = new Date().getTime();
      const response = await CoPayFsService({
        affiliate_id: selectedAffiliate || affiliation_id || '20',
      });
      const endTimeSNS = new Date().getTime();
      const totalTimeSNS = endTimeSNS - startTime;
      setApiResponseTimes(prevTimes => [...prevTimes, totalTimeSNS]);
      console.log('response:>', response);
      if (response.CopayFs.length) {
        setCopayFSSuggestions(response.CopayFs);
      } else {
        setCopayFSSuggestions([]);
      }

      // setCopayFSSuggestions(dummyCopayFS);
    } catch (error) {
      console.error('Error fetching Copay FS suggestions:', error);
    } finally {
      setCopayFSLoading(false);
    }
  };

  // Function to handle the selection of a Copay FS option
  const handleCopayFSSelect = async (copay) => {
    try {
      setChangesUpdated(false)
      // Capture the start time
      const startTime = new Date().getTime();
      const responsePayment = await PaymentTypeService(copay?.iCopayFSID.toString());
      const endTimeSNS = new Date().getTime();
      const totalTimeSNS = endTimeSNS - startTime;
      setApiResponseTimes(prevTimes => [...prevTimes, totalTimeSNS]);
      if (responsePayment.PaymentTypes) {
        handleCopayPaymentSelect(responsePayment.PaymentTypes[0]);
      }
    } catch (error) {
      console.error('Error fetching payment type suggestions:', error);
    } finally {
      setPaymentTypeLoading(false);
    }
    setMessages(prevMessages => {
      const updatedMessages = [...prevMessages];
      const currentMessageIndex = updatedMessages.length - 1;

      updatedMessages[currentMessageIndex] = {
        ...updatedMessages[currentMessageIndex],
        answer: updatedMessages[currentMessageIndex].answer.replace(
          /(Copay FS:\s*)(.*?)(\n|$)/,
          `$1${copay?.COPAY_FS_NAME}$3`
        ),
        args: {
          ...updatedMessages[currentMessageIndex].args,
          copay_fs: copay?.COPAY_FS_NAME,
        },
      };
      setSelectedCopayFsId(copay?.iCopayFSID)
      setDisableCopayDropdown(true);
      setChangesUpdated(true)
      return updatedMessages;
    });

    setArgsState(prevArgs => ({
      ...prevArgs,
      copay_fs: copay?.COPAY_FS_NAME,
    }));

    setOpenDropdown(null);
  };

  // Function to handle the selection of a Copay FS option
  const handleCopayPaymentSelect = copay => {
    console.log("copay", copay)
    setMessages(prevMessages => {
      const updatedMessages = [...prevMessages];
      const currentMessageIndex = updatedMessages.length - 1;

      updatedMessages[currentMessageIndex] = {
        ...updatedMessages[currentMessageIndex],
        answer: updatedMessages[currentMessageIndex].answer.replace(
          /(Copay Payment Type:\s*)(.*?)(\n|$)/,
          `$1${copay}$3`
        ),
        args: {
          ...updatedMessages[currentMessageIndex].args,
          copay_pt: copay,
        },
      };
      return updatedMessages;
    });

    setArgsState(prevArgs => ({
      ...prevArgs,
      copay_pt: copay,
    }));

    setOpenDropdown(null);
  };

  const fetchCopayPaymentSuggestions = async () => {
    try {
      setCopayPaymentLoading(true);
      setChangesUpdated(false)
      // Capture the start time
      const startTime = new Date().getTime();
      const response = await PaymentTypeService(
        selectedCopayFsId.toString()
      );
      const endTimeSNS = new Date().getTime();
      const totalTimeSNS = endTimeSNS - startTime;
      setApiResponseTimes(prevTimes => [...prevTimes, totalTimeSNS]);
      if (response.PaymentTypes) {
        setCopayPaymentSuggestions(response.PaymentTypes);
      } else {
        setCopayPaymentSuggestions([]);
      }
    } catch (error) {
      console.error('Error fetching Copay FS suggestions:', error);
    } finally {
      setCopayPaymentLoading(false);
    }
  };

  const [fareEstimates, setFareEstimates] = useState({});
  const [distanceEstimates, setDistanceEstimates] = useState({});
  const [timeEstimates, setTimeEstimates] = useState({});

  const calculateFares = async () => {
    if (!Array.isArray(argsState.pickup_legs)) {
      console.error('pickup_legs is not an array or is undefined:', argsState.pickup_legs);
      return; // Exit early if pickup_legs is not an array
    }

    const newFareEstimates = {};
    const newDistanceEstimates = {};
    const newTimeEstimates = {};

    for (const [legIndex, leg] of argsState.pickup_legs.entries()) {
      try {
        // Capture the start time
        const startTime = new Date().getTime();
        const fareEstimateResponse = await FetchFareEstimate(selectedFundingSource.toString(), leg.pickup_address, leg.dropoff_address);
        const endTimeSNS = new Date().getTime();
        const totalTimeSNS = endTimeSNS - startTime;
        setApiResponseTimes(prevTimes => [...prevTimes, totalTimeSNS]);
        console.log("fareEstimateResponse", fareEstimateResponse)
        newFareEstimates[legIndex] = '$' + fareEstimateResponse.FairEstimate || 'N/A'
        newDistanceEstimates[legIndex] = fareEstimateResponse.DistanceEstimate + ' km' || 'N/A'
        newTimeEstimates[legIndex] = fareEstimateResponse.TimeEstimate + ' minutes' || 'N/A'
      } catch (error) {
        console.error(`Error fetching fare for leg ${legIndex + 1}:`, error);
        newFareEstimates[legIndex] = 'Error fetching fare'; // Handle errors appropriately
      }
    }

    setFareEstimates(newFareEstimates); // Update state with all fare estimates
    setDistanceEstimates(newDistanceEstimates); // Update state with all fare estimates
    setTimeEstimates(newTimeEstimates); // Update state with all fare estimates
  };

  useEffect(() => {
    calculateFares(); // Call the function to calculate fares when component mounts
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [argsState.pickup_legs, argsState]); // Recalculate if pickup_legs change

  // Local state to handle the search query
  const [searchQuery, setSearchQuery] = useState('');

  // Function to filter suggestions based on the search query
  const filteredSuggestions = fundingSourceSuggestions?.filter((source) =>
    source.FS_NAME.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // State to handle the search query input
  const [copayFSSearchQuery, setCopayFSSearchQuery] = useState('');

  // Filter the suggestions based on the search query
  const filteredCopayFSSuggestions = copayFSSuggestions?.filter(copay =>
    copay.COPAY_FS_NAME.toLowerCase().includes(copayFSSearchQuery.toLowerCase())
  );


  const renderText = (args, isLastMessage) => {
    const paymentTypePhrases = ['Payment Type'];
    const copayFSPhrases = ['Co-pay FS'];
    const copayPaymentPhrases = ['Co-pay Payment Type'];

    const renderLine = (line, index) => {
      const [label, value] = line.split(':')
      if (isLastMessage && line.includes('Funding Source')) {
        return (
          <div key={index} style={{ marginTop: "2px" }}>
            <span style={{ whiteSpace: 'normal' }}><strong>{label?.trim()}:</strong>{" "}{value && value.trim()}</span>
            <Button
              size="xs"
              style={{ maxWidth: "31px", backgroundColor: "#a09e9e" }}
              onClick={() => {
                if (!fundingSourceSuggestions.length) {
                  fetchFundingSourceSuggestions();
                }
                setOpenDropdown(
                  openDropdown === 'fundingSource' ? null : 'fundingSource'
                );
                setSearchQuery('');
              }}
              rightIcon={
                openDropdown === 'fundingSource' ? (
                  <MdExpandLess style={{ fontSize: '18px', color: "white" }} />
                ) : (
                  <MdExpandMore style={{ fontSize: '18px', color: "white" }} />
                )
              }
              ml={2}
            >

            </Button>
            {openDropdown === 'fundingSource' && (
              <Box
                mt={2}
                bg="white"
                color="black"
                boxShadow="md"
                borderRadius="md"
                width="100%"
                ref={dropdownRef}
                maxHeight="150px"
                overflowY="auto"
              >
                {/* Fixed Search input field */}
                <Box bg="white" p={'5px 5px'} position="sticky" top="0" zIndex="1">
                  <Input
                    placeholder="Search funding source..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  // mb={2}
                  />
                </Box>

                {fundingSourceLoading ? (
                  <ThreeDots
                    height="50"
                    width="50"
                    radius="9"
                    color="#17496D"
                    ariaLabel="three-dots-loading"
                  />
                ) : (
                  <List>
                    {filteredSuggestions?.length > 0 ? (
                      filteredSuggestions.map((source, idx) => (
                        <ListItem
                          key={idx}
                          onClick={() => handleFundingSourceSelect(source)}
                          _hover={{
                            backgroundColor: 'gray.100',
                            cursor: 'pointer',
                          }}
                          p={2}
                        // borderBottom="1px solid #e0e0e0" // Adding space between options
                        // borderRadius="md"
                        >
                          {source.FS_NAME}
                        </ListItem>
                      ))
                    ) : (
                      <ListItem textAlign={'center'} mt={1} mb={1}>No results found</ListItem>
                    )}
                  </List>
                )}
              </Box>
            )}
          </div>
        );
      }

      if (
        isLastMessage &&
        copayFSPhrases.some(phrase => line.includes(phrase))
      ) {
        return (
          <div key={index} style={{ marginTop: "2px" }}>
            <span style={{ whiteSpace: 'normal' }}><strong>{label?.trim()}:</strong>{" "}{value && value.trim()}</span>
            <Button
              size="xs"
              style={{ maxWidth: "31px", backgroundColor: "#a09e9e" }}
              // colorScheme="blue"
              onClick={() => {
                fetchCopayFSSuggestions();
                setOpenDropdown(openDropdown === 'copayFS' ? null : 'copayFS');
                setCopayFSSearchQuery("")
              }}
              rightIcon={
                openDropdown === 'copayFS' ? <MdExpandLess style={{ fontSize: '18px', color: "white" }} /> : <MdExpandMore style={{ fontSize: '18px', color: "white" }} />
              }
              ml={2}
            >
            </Button>
            {openDropdown === 'copayFS' && (
              <Box
                mt={2}
                bg="white"
                color="black"
                boxShadow="md"
                borderRadius="md"
                width="100%"
                ref={dropdownRef}
                maxHeight="150px"
                overflowY="auto"
              >
                {/* Fixed Search input field */}
                <Box bg="white" p={'5px 5px'} position="sticky" top="0" zIndex="1">
                  <Input
                    placeholder="Search Co-pay FS..."
                    value={copayFSSearchQuery}
                    onChange={(e) => setCopayFSSearchQuery(e.target.value)}
                  // mb={2}
                  />
                </Box>
                {copayFSLoading ? (
                  <ThreeDots
                    height="50"
                    width="50"
                    radius="9"
                    color="#17496D"
                    ariaLabel="three-dots-loading"
                  />
                ) : (
                  <List>
                    {filteredCopayFSSuggestions?.length > 0 ? (
                      filteredCopayFSSuggestions.map((copay, idx) => (
                        <ListItem
                          key={idx}
                          onClick={() => handleCopayFSSelect(copay)}
                          _hover={{
                            backgroundColor: 'gray.100',
                            cursor: 'pointer',
                          }}
                          p={2}
                        // borderRadius="md"
                        >
                          {copay?.COPAY_FS_NAME}
                        </ListItem>
                      ))
                    ) : (
                      <ListItem textAlign={'center'} mt={1} mb={1}>No results found</ListItem>
                    )}
                  </List>
                )}
              </Box>
            )}
          </div>
        );
      }

      if (
        isLastMessage &&
        copayPaymentPhrases.some(phrase => line.includes(phrase))
      ) {
        return (
          <div key={index} style={{ marginTop: "2px" }}>
            <span style={{ whiteSpace: 'normal' }}><strong>{label?.trim()}:</strong>{" "}{value && value.trim()}</span>
            {disableCopayDropdown && <Button
              size="xs"
              // colorScheme="blue"
              style={{ maxWidth: "31px", backgroundColor: "#a09e9e" }}
              onClick={() => {
                fetchCopayPaymentSuggestions();
                setOpenDropdown(
                  openDropdown === 'copayPayment' ? null : 'copayPayment'
                );
              }}
              rightIcon={
                openDropdown === 'copayPayment' ? (
                  <MdExpandLess style={{ fontSize: '18px', color: "white" }} />
                ) : (
                  <MdExpandMore style={{ fontSize: '18px', color: "white" }} />
                )
              }
              ml={2}
            >
            </Button>}
            {openDropdown === 'copayPayment' && (
              <Box
                mt={2}
                bg="white"
                color="black"
                p={2}
                boxShadow="md"
                borderRadius="md"
                width="100%"
                ref={dropdownRef}
                maxHeight="150px"
                overflowY="auto"
              >
                {copayPaymentLoading ? (
                  <ThreeDots
                    height="50"
                    width="50"
                    radius="9"
                    color="#17496D"
                    ariaLabel="three-dots-loading"
                  />
                ) : (
                  <List>
                    {copayPaymentSuggestions?.map((copay, idx) => (
                      <ListItem
                        key={idx}
                        onClick={() => handleCopayPaymentSelect(copay)}
                        _hover={{
                          backgroundColor: 'gray.100',
                          cursor: 'pointer',
                        }}
                        p={2}
                        borderRadius="md"
                      >
                        {copay}
                      </ListItem>
                    ))}
                  </List>
                )}
              </Box>
            )}
          </div>
        );
      }

      if (
        isLastMessage &&
        paymentTypePhrases.some(phrase => line.includes(phrase))
      ) {
        return (
          <div key={index} style={{ marginTop: "2px" }}>
            <span style={{ whiteSpace: 'normal' }}><strong>{label?.trim()}:</strong>{" "}{value && value.trim()}</span>
            <Button
              size="xs"
              // colorScheme="blue"
              style={{ maxWidth: "31px", backgroundColor: "#a09e9e" }}
              onClick={() => {
                fetchPaymentTypeSuggestions();
                setOpenDropdown(
                  openDropdown === 'paymentType' ? null : 'paymentType'
                );
              }}
              rightIcon={
                openDropdown === 'paymentType' ? (
                  <MdExpandLess style={{ fontSize: '18px', color: "white" }} />
                ) : (
                  <MdExpandMore style={{ fontSize: '18px', color: "white" }} />
                )
              }
              ml={2}
            >
            </Button>
            {openDropdown === 'paymentType' && (
              <Box
                mt={2}
                bg="white"
                color="black"
                p={2}
                boxShadow="md"
                borderRadius="md"
                width="100%"
                ref={dropdownRef}
                maxHeight="150px"
                overflowY="auto"
              >
                {paymentTypeLoading ? (
                  <ThreeDots
                    height="50"
                    width="50"
                    radius="9"
                    color="#17496D"
                    ariaLabel="three-dots-loading"
                  />
                ) : (
                  <List>
                    {paymentTypeSuggestions?.map((type, idx) => (
                      <ListItem
                        key={idx}
                        onClick={() => handlePaymentTypeSelect(type)}
                        _hover={{
                          backgroundColor: 'gray.100',
                          cursor: 'pointer',
                        }}
                        p={2}
                        borderRadius="md"
                      >
                        {type}
                      </ListItem>
                    ))}
                  </List>
                )}
              </Box>
            )}
          </div>
        );
      }

      return (
        <p style={{ whiteSpace: 'normal' }} key={index}>
          <strong>{label?.trim()}:</strong>{" "}{value && value.trim()}
        </p>
      );
    };

    // Format the text and include stops
    const pickupLegsText = args.pickup_legs.map((leg, legIndex) => {
      return (
        <div key={legIndex}>
          {args?.trip_type === 'multi' || args?.trip_type === 'return' ? <div>
            <strong>Leg {legIndex + 1}:</strong>
          </div> : null}
          <div style={{ paddingLeft: args?.trip_type === 'multi' || args?.trip_type === 'return' ? "15px" : "0px" }}>
            <div>
              <strong>Pickup Address:</strong> {leg?.pickup_address}
              <Menu>
                <MenuButton
                  as={Button}
                  size="xs"
                  // colorScheme="blue"
                  style={{ maxWidth: "31px", backgroundColor: "#a09e9e" }}
                  rightIcon={
                    openDropdown === 'pickup' ? (
                      <MdExpandLess style={{ fontSize: '18px', color: "white" }} />
                    ) : (
                      <MdExpandMore style={{ fontSize: '18px', color: "white" }} />
                    )
                  }
                  onClick={() => {
                    setPickupLoading(true);
                    fetchAddressSuggestions(leg?.pickup_address, 'pickup').then(
                      () => {
                        setPickupLoading(false);
                      }
                    );
                    setOpenDropdown(openDropdown === 'pickup' ? null : 'pickup');
                  }}
                  ml={2}
                  my={1}
                >
                </MenuButton>
                <MenuList width="15rem">
                  {pickupLoading ? (
                    <MenuItem>
                      <ThreeDots
                        height="50"
                        width="50"
                        radius="9"
                        color="#17496D"
                        ariaLabel="three-dots-loading"
                      />
                    </MenuItem>
                  ) : (
                    pickupSuggestions?.map((address, idx) => (
                      <MenuItem
                        key={idx}
                        onClick={() =>
                          handleAddressSelect(address, 'pickup', legIndex)
                        }
                      >
                        {address}
                      </MenuItem>
                    ))
                  )}
                </MenuList>
              </Menu>
            </div>
            {leg?.pickup_remarks && <div>Pickup Remarks: {leg?.pickup_remarks}</div>}
            <div>
              <strong>Dropoff Address:</strong> {leg?.dropoff_address}
              <Menu>
                <MenuButton
                  as={Button}
                  size="xs"
                  style={{ maxWidth: "31px", backgroundColor: "#a09e9e" }}
                  // colorScheme="blue"
                  rightIcon={
                    openDropdown === 'dropoff' ? (
                      <MdExpandLess style={{ fontSize: '18px', color: "white" }} />
                    ) : (
                      <MdExpandMore style={{ fontSize: '18px', color: "white" }} />
                    )
                  }
                  onClick={() => {
                    setDropoffLoading(true);
                    fetchAddressSuggestions(leg?.dropoff_address, 'dropoff').then(
                      () => {
                        setDropoffLoading(false);
                      }
                    );
                    setOpenDropdown(
                      openDropdown === 'dropoff' ? null : 'dropoff'
                    );
                  }}
                  ml={2}
                  my={1}
                >
                </MenuButton>
                <MenuList width="15rem">
                  {dropoffLoading ? (
                    <MenuItem>
                      <ThreeDots
                        height="50"
                        width="50"
                        radius="9"
                        color="#17496D"
                        ariaLabel="three-dots-loading"
                      />
                    </MenuItem>
                  ) : (
                    dropoffSuggestions?.map((address, idx) => (
                      <MenuItem
                        key={idx}
                        onClick={() =>
                          handleAddressSelect(address, 'dropoff', legIndex)
                        }
                      >
                        {address}
                      </MenuItem>
                    ))
                  )}
                </MenuList>
              </Menu>
            </div>
            {leg?.dropoff_remarks && <div><strong>Dropoff Remarks:</strong> {leg?.dropoff_remarks}</div>}
            <div><strong>Pickup Date:</strong> {leg?.pickup_date}</div>
            <div><strong>Pickup Time:</strong> {leg?.pickup_time}</div>
            {leg.app_time && (
              <div>
                <strong>Appointment Time:</strong> {leg.app_time}
              </div>
            )}

            {/* Display the fare estimate */}
            <div>
              <strong>Fare Estimate:</strong> {fareEstimates[legIndex] ? `${fareEstimates[legIndex]}` : 'Loading...'}
            </div>

            {/* Display the Distance estimate */}
            <div>
              <strong>Distance Estimate:</strong> {distanceEstimates[legIndex] ? `${distanceEstimates[legIndex]}` : 'Loading...'}
            </div>

            {/* Display the Time estimate */}
            <div>
              <strong>Time Estimate:</strong> {timeEstimates[legIndex] ? `${timeEstimates[legIndex]}` : 'Loading...'}
            </div>


            {leg?.stops &&
              leg.stops.length > 0 &&
              leg.stops.map((stopObj, stopIndex) => (
                <div key={stopIndex}>
                  <div>
                    <strong>Stop:</strong>
                  </div>
                  <div style={{ paddingLeft: args?.trip_type === 'multi' || args?.trip_type === 'return' ? "15px" : "0px" }}>
                    <div>
                      <strong>Stop Address:</strong> {stopObj.stop_address}
                      <Menu>
                        <MenuButton
                          as={Button}
                          size="xs"
                          style={{ maxWidth: "31px", backgroundColor: "#a09e9e" }}
                          // colorScheme="blue"
                          rightIcon={
                            openDropdown === `stop_${legIndex}_${stopIndex}` ? (
                              <MdExpandLess style={{ fontSize: '18px', color: "white" }} />
                            ) : (
                              <MdExpandMore style={{ fontSize: '18px', color: "white" }} />
                            )
                          }
                          onClick={() => {
                            setStopLoading(true);
                            fetchAddressSuggestions(
                              stopObj.stop_address,
                              'stop'
                            ).then(() => setStopLoading(false));
                            setOpenDropdown(
                              openDropdown === `stop_${legIndex}_${stopIndex}`
                                ? null
                                : `stop_${legIndex}_${stopIndex}`
                            );
                          }}
                          ml={2}
                          my={1}
                        >
                        </MenuButton>
                        <MenuList width="15rem">
                          {stopLoading ? (
                            <MenuItem>
                              <ThreeDots
                                height="50"
                                width="50"
                                radius="9"
                                color="#17496D"
                                ariaLabel="three-dots-loading"
                              />
                            </MenuItem>
                          ) : (
                            stopSuggestions?.map((address, idx) => (
                              <MenuItem
                                key={idx}
                                onClick={() =>
                                  handleAddressSelect(
                                    address,
                                    'stop',
                                    legIndex,
                                    stopIndex
                                  )
                                }
                              >
                                {address}
                              </MenuItem>
                            ))
                          )}
                        </MenuList>
                      </Menu>
                    </div>
                    <div><strong>Stop Duration:</strong> {stopObj.stop_duration} minutes</div>
                  </div>
                </div>
              ))}

          </div>
        </div>
      );
    });

    const pickupLegs = args.pickup_legs;
    let tripTypeText = '';

    if (pickupLegs.length > 1) {
      tripTypeText = args.trip_type === 'multi' ? 'Multi Leg Trip' : 'Return Trip';
    } else if (pickupLegs.length === 1) {
      tripTypeText = 'Single Person Single Trip';
    }

    const formattedText = `
    ${args.passenger_name ? `Passenger Name: ${args.passenger_name}` : ''}
    ${args.clientphone ? `Phone Number: ${args.clientphone}` : ''}
    ${args.affiliatename ? `Affiliate Name: ${args.affiliatename}` : ''}
    ${args.ride_remarks ? `Ride Remarks: ${args.ride_remarks}` : ''}
    ${args.callback_phone ? `Call Back Phone: ${args.callback_phone}` : ''}
    ${tripTypeText ? `Trip Type: ${tripTypeText}` : ''}
    ${riderBalance ? `Rider Balance: ${riderBalance}` : ''}
    ${args.payment_type ? `Payment Type: ${args.payment_type}` : ''}
    ${args.funding_source ? `Funding Source: ${args.funding_source}` : ''}
    Co-pay FS: ${args.copay_fs === '' ? 'N/A' : args.copay_fs}
    ${args.copay_pt ? `Co-pay Payment Type: ${args.copay_pt}` : ''}
`.trim(); // Optional: trim to remove any extra leading/trailing spaces or newlines



    return formattedText
      .split('\n')
      .filter(line => line.length >= 5)
      .map(renderLine)
      .concat(pickupLegsText);
  };

  const sendMessage = value => {
    let data_sent = JSON.stringify({
      user_msg: value,
    });
    if (
      chatSocket.current &&
      chatSocket.current.readyState === WebSocket.OPEN
    ) {
      chatSocket.current.send(data_sent);
    } else {
      console.error('WebSocket is not open');
    }
    setFocusedSuggestionIndex(-1);
  };

  const debounceInputChange = useCallback(
    debounce(value => {
      if (value !== '' && value.slice(-1) !== '\b') {
        sendMessage(value);
      }
    }, 3000),
    []
  );

  const debounceInput1sec = useCallback(
    debounce(value => {
      if (value !== '' && value.slice(-1) !== '\b') {
        sendMessage(value);
      }
    }, 1000),
    []
  );

  const handleInputChange = event => {
    const value = event.target.value;

    const keyPressed = event.nativeEvent.inputType === 'deleteContentBackward';

    if (value === '') {
      setSuggestions([]);
      setChangesUpdated(false);
    } else {
      setChangesUpdated(true);
    }
    setNewMessage(value);
    setBaseMessage(value);
    textareaRef.current.style.height = '40px';
    textareaRef.current.style.height = `${Math.min(
      textareaRef.current.scrollHeight,
      100
    )}px`;

    const regex = /\d+\s[A-Za-z]{3,}$/;
    if (regex.test(value) && !keyPressed) {
      debounceInput1sec(value);
    }

    debounceInputChange(value);
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter' && !event.shiftKey && !loading) {
      event.preventDefault();

      if (suggestions?.length === 0) {
        handleSubmit(newMessage);
      } else {
        setSuggestions([]);
      }
    } else if (event.key === 'ArrowUp' && suggestions.length > 0) {
      event.preventDefault();
      setFocusedSuggestionIndex(prevIndex => {
        const newIndex = prevIndex > 0 ? prevIndex - 1 : suggestions.length - 1;
        scrollToSuggestion(newIndex);
        return newIndex;
      });
    } else if (event.key === 'ArrowDown' && suggestions.length > 0) {
      event.preventDefault();
      setFocusedSuggestionIndex(prevIndex => {
        const newIndex = prevIndex < suggestions.length - 1 ? prevIndex + 1 : 0;
        scrollToSuggestion(newIndex);
        return newIndex;
      });
    } else if (event.key === 'Tab' && suggestions.length > 0) {
      event.preventDefault();
      setFocusedSuggestionIndex(prevIndex => {
        const newIndex = prevIndex < suggestions.length - 1 ? prevIndex + 1 : 0;
        scrollToSuggestion(newIndex);
        return newIndex;
      });
    } else if (event.key === ' ') {
      setSuggestions([]);
    }
  };

  const scrollToSuggestion = index => {
    if (suggestionRefs.current[index]) {
      suggestionRefs.current[index].scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  };

  useEffect(() => {
    if (
      focusedSuggestionIndex >= 0 &&
      focusedSuggestionIndex < suggestions.length
    ) {
      const regex = new RegExp(suggestionText, 'i');
      const selectedSuggestion = suggestions[focusedSuggestionIndex];

      let updatedMessage;

      if (regex.test(selectedSuggestion)) {
        updatedMessage = `${baseMessage}${selectedSuggestion?.replace(
          regex,
          ''
        )}`;
      } else {
        const modifiedBaseMessage = baseMessage.replace(regex, '');
        updatedMessage = `${modifiedBaseMessage}${selectedSuggestion}`;
      }

      setNewMessage(updatedMessage);
    }
  }, [focusedSuggestionIndex, suggestions, baseMessage, suggestionText]);

  const removeSuggestion = index => {
    setSuggestions(prevSuggestions =>
      prevSuggestions.filter((_, i) => i !== index)
    );
  };

  return (
    <div className="main-container">
      <div className="chat-icon" onClick={toggleChat}>
        <MdChatBubbleOutline color="white" fontSize={'20px'} />
      </div>

      {isChatOpen && (
        <div className="chat-window">
          <Stack
            borderTopRadius={'20px'}
            px={6}
            py={3}
            direction="row"
            bg="#4297d7"
            w="100%"
            spacing={3}
            alignItems={'center'}
          >
            <Img w={12} src={chatLogo} />
            <Stack fontSize="sm" spacing={0} flex={1}>
              <Text fontWeight={'500'} color="white">
                ITC Live Chat
              </Text>
              <Flex alignItems={'center'} gap={1}>
                <Img src={online} />
                <Text color="#E9E9E9">online</Text>
              </Flex>
            </Stack>
            {/* Dropdown for affiliates */}
            {affiliates.length > 0 && (
              <select
                disabled={affiliatesDisabled}
                value={selectedAffiliate}
                onChange={handleAffiliateChange}
                style={{
                  padding: '3px 4px ',
                  borderRadius: '4px',
                  border: '1px solid #ccc',
                  fontSize: '13px',
                  maxWidth: '120px',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              >
                {affiliates.map((affiliate) => (
                  <option key={affiliate.id} value={affiliate.id}>
                    {affiliate.name}
                  </option>
                ))}
              </select>
            )}
            <div className="tooltip">
              <IoMdRefresh
                onClick={() => {
                  const newUuid = generateRandomNumber().toString();
                  setUuid(newUuid);
                  fetchCustomerName(newUuid)
                  // setMessages([initialMessage]);
                  setAffiliatesDisabled(false);
                  setApiResponseTimes([])
                }}
                cursor="pointer"
                fontSize={22}
                color="white"
              />
              <span className="tooltip-text">Refresh</span>
            </div>
          </Stack>
          <Stack
            ref={faqRef}
            spacing={4}
            px={6}
            py={4}
            className="message-container"
            position="relative"
            overflowY="auto"
            overflowX="hidden"
            maxHeight="calc(100vh - 100px)"
            width="100%"
          >
            {messages?.map((message, index) => (
              <Stack key={index}>
                {message?.query !== '' ? (
                  <Stack justifyContent="end" direction={'row'}>
                    <Text whiteSpace={'break-spaces'} className="user-message" >
                      {message?.query}
                    </Text>
                  </Stack>
                ) : null}
                <Stack pt={2}>
                  {message?.loading ? (
                    <ThreeDots
                      height="50"
                      width="50"
                      radius="9"
                      color="#17496D"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      visible={true}
                    />
                  ) : message?.answer !== '' ? (
                    <Stack spacing={8} direction={'row'}>
                      <Img w={12} h={12} src={filledLogo} />
                      <Text
                        whiteSpace={'break-spaces'}
                        className={
                          message?.answer ===
                            'There was an issue processing your request. Please try again.'
                            ? 'user-error-message'
                            : 'bot-message'
                        }
                      >
                        {message?.args != null
                          ? renderText(
                            message?.args,
                            index === messages.length - 1 // Check if this is the last message
                          )
                          : null}

                        {message?.errorMessage === '' ?
                          <span>{renderMessageText(message?.answer)}</span>
                          : <div style={{ color: "red" }}>{message?.errorMessage}</div>}

                        {/* Check if this is the last message and render API times */}
                        {index === messages.length - 1 ? (
                          <div>
                            {apiResponseTimes.length > 0 ? (
                              <h5
                                style={{
                                  color: 'grey',
                                  fontSize: '10px',
                                  marginTop: '',
                                }}
                              >
                                {apiResponseTimes.join(' ms || ')} ms
                              </h5>
                            ) : null}
                          </div>
                        ) : null}
                      </Text>
                    </Stack>
                  ) : null}
                </Stack>
              </Stack>
            ))}

          </Stack>

          <Stack px={6} py={2} className="input-group" position="relative">
            {suggestions?.length > 0 && (
              <Box
                mb={2}
                bg="white"
                borderRadius="md"
                boxShadow="md"
                zIndex={1}
                position="absolute"
                width="90%"
                bottom="89px"
                p={2}
                maxHeight="200px"
                overflowY="auto"
              >
                <List spacing={1}>
                  {suggestions?.map((suggestion, index) => (
                    <ListItem
                      key={index}
                      p={2}
                      borderRadius="md"
                      bg={
                        index === focusedSuggestionIndex ? 'gray.100' : 'white'
                      }
                      _hover={{
                        backgroundColor: 'gray.100',
                        cursor: 'pointer',
                      }}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      onMouseEnter={() => setHoveredSuggestionIndex(index)}
                      onMouseLeave={() => setHoveredSuggestionIndex(-1)}
                      boxShadow="sm"
                      transition="all 0.2s"
                      ref={el => (suggestionRefs.current[index] = el)}
                      onMouseDown={e => {
                        e.preventDefault();
                        const regex = new RegExp(suggestionText, 'i');

                        const selectedSuggestion = suggestion;

                        let updatedMessage;

                        if (regex.test(selectedSuggestion)) {
                          updatedMessage = `${baseMessage}${selectedSuggestion?.replace(
                            regex,
                            ''
                          )}`;
                        } else {
                          const modifiedBaseMessage = baseMessage.replace(
                            regex,
                            ''
                          );
                          updatedMessage = `${modifiedBaseMessage}${selectedSuggestion}`;
                        }

                        setNewMessage(updatedMessage);
                      }}
                    >
                      <span>{suggestion}</span>
                      {hoveredSuggestionIndex === index && (
                        <Box
                          onClick={() => removeSuggestion(index)}
                          cursor="pointer"
                          p={1}
                          borderRadius="full"
                          _hover={{ color: 'red' }}
                          transition="color 0.2s"
                        >
                          <MdClose />
                        </Box>
                      )}
                    </ListItem>
                  ))}
                </List>
              </Box>
            )}
            <InputGroup
              bg="transparent"
              w="100%"
              alignItems="center"
              position={'relative'}
            >
              <Textarea
                ref={textareaRef}
                variant="unstyled"
                placeholder="How can I help?"
                value={newMessage}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                resize="none"
                style={{
                  maxHeight: '100px',
                  overflowY: 'auto',
                  whiteSpace: 'pre-wrap',
                  fontSize: '13px',
                  marginRight: '30px',
                  paddingRight: '20px',
                }}
              />
              <InputRightAddon
                position={'absolute'}
                top={0}
                right={0}
                onClick={() => handleSubmit(newMessage)}
                cursor="pointer"
                bg="transparent"
                border="none"
                p="0"
                alignItems="center"
              >
                <IoMdSend color={changesUpdated ? "#4297d7" : "#999999"} fontSize={20} />
              </InputRightAddon>
            </InputGroup>
          </Stack>
        </div>
      )}
    </div>
  );
};

export default ChatWidget;
